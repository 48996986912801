main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  line-height: 1.6;

  @include themify($themes) {
    color: themed('colorText');
  }
}

* {
  box-sizing: inherit;
}

ul, ol {
  padding-left: 15px;
  margin-bottom: 0;
}

.fa-ul{
  margin-left: 1.5em;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

/******************* bsCardV6 ***********************/

.bsCardV6 .bsCardV6Overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(top, rgba(255,0,0,0) 0%, #222222 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#222222), color-stop(100%,rgba(255,0,0,0)));
  background: -webkit-linear-gradient(top, rgba(255,0,0,0) 0%,#222222 100%);
  background: -o-linear-gradient(top, rgba(255,0,0,0) 0%,#222222 100%);
  background: -ms-linear-gradient(top, rgba(255,0,0,0) 0%,#222222 100%);
  background: linear-gradient(to bottom, rgba(255,0,0,0) 0%,#222222 100%);
  opacity: .8;
  border-radius: 10px;
}

.bsCardV6 .bsCardV6Title{
  font-family: 'Nunito Sans', sans-serif;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 900;
  text-shadow: 0px 0px 3px #222222;
}

.bsCardV6 input{
  color:#ffffff;
  width: 2rem;
  height: 2rem;
}
.bsCardV6 label{
  color:#ffffff;
}

.bsCardV6 img{
  height:200px;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}