#listInformation {

}

.themed-border-right{
  border-right:1px solid;
  @include themify($themes) {
    border-color: themed('colorBorder');
  }
}

.themed-border-top{
  border-top:1px solid;
  @include themify($themes) {
    border-color: themed('colorBorder');
  }
}

.listItemContainer .listCardHeader .listItemCardTitle{
  height: 50px;
  overflow: hidden;
}

.listsContainer{
  &::-webkit-scrollbar {
    height: 8px;
    width: 6px;
    opacity: 0.3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid transparent;
  }

  &::-webkit-scrollbar-thumb {

    @include themify($themes) {
      background-color: themed('colorScrollbar');
    }
  }
}

